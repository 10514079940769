import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import LoadPage from "./shared/loading";
const Home = React.lazy(() => import("./pages/home/home"));
const Courses = React.lazy(() => import("./pages/Courses"));
const Services = React.lazy(() => import("./pages/Services"));
const Contact = React.lazy(() => import("./pages/Contact"));
const Instructor = React.lazy(() => import("./pages/Instructor"));
const AboutInstitute = React.lazy(() => import("./pages/AboutInstitute"));
const TypeWritingCourse = React.lazy(() => import("./pages/TypeWritingCourse"));
const Coa = React.lazy(() => import("./pages/Coa"));
const ShortHand = React.lazy(() => import("./pages/Shorthand"));
function App() {
  return (
    <div className="App">
      <Suspense fallback={<LoadPage />}>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route
              path="type-writing-saraswathi-courses"
              element={<Courses />}
            />
            <Route
              path="type-writing-saraswathi-services"
              element={<Services />}
            />
            <Route path="type-institute-address" element={<Contact />} />
            <Route path="type-institute-instructor" element={<Instructor />} />
            <Route
              path="type-institute-saraswathi-about"
              element={<AboutInstitute />}
            />

            <Route path="type-writing-course" element={<TypeWritingCourse />} />

            <Route
              path="computer-office-automation-courses"
              element={<Coa />}
            />
            <Route path="shorthand-course" element={<ShortHand />} />
          </Route>
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
