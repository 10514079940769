import React from "react";
import { NavLink } from "react-router-dom";
import { servicesData } from "../shared/ServicesData";
import "./../style.css";
import "./footer.css";
export default function Footer() {
  return (
    <>
      <section className="noti_row py-4 bg_1">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <h1 className="fs-3 mb-0 text-white text-center text-lg-start font_500 pe-md-4 pb-3 pb-lg-0">
                Tamil and english type writing admission is going on...
              </h1>
              
            </div>
            <div className="col-lg-4 text-center text-lg-end">
            <NavLink
                to="/"
                className="btn rounded-pill border text-white px-4 d-inline-flex align-items-center justify-content-center"
              >
                <h4 className="font_500 mb-0 fs-5">
                  {" "}
                  <i className="bi bi-telephone-forward-fill i_lh fs-5 text-white pe-2"></i>{" "}
                  +91 9080472321
                </h4>
              </NavLink>
              
            </div>
          </div>
        </div>
      </section>      
      <footer className="footer_row cus_pad_1 pb-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 pb-3 pb-md-0">
              <h3 className="font_600 fs-5 color_1">Our Courses</h3>
              <ul className="row row-cols-1">
                <li>
                  <NavLink className="text-dark" to="/type-writing-course">
                    Type writing
                  </NavLink>
                </li>
                <li>
                  <NavLink className="text-dark" to="/shorthand-course">
                    Shorthand
                  </NavLink>
                </li>
                <li>
                  <NavLink className="text-dark" to="/computer-office-automation-courses">
                    Computer office automation
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-12 pb-3 pb-md-0">
              <h3 className="font_600 fs-5 color_1">Our Services</h3>
              <ul className="row row-cols-1">
                {servicesData.map((item, index) => {
                  return <li key={index} className="col">{item}</li>;
                })}
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 pb-3 pb-md-0">
              <h3 className="font_600 fs-5 color_1">Address</h3>

              <h1 className="font_600 fs-6">
                Sri Saraswathi Typewriting Institute
              </h1>
              <address className="mb-0">
                108 Meenakshi Mill Colony, <br /> HP Petrol Bunk Opposite,
                <br />
                Andalpuram, Chathrapatti Road,
                <br /> Rajapalayam - 626 108.
              </address>
            </div>
            <div className="col-lg-3 col-md-6 pb-3 pb-md-0">
              <h3 className="font_600 fs-5 color_1">Contact</h3>
              <div className="border-bottom mb-2 pb-2">
                <b className="font_600 color_1">Phone : </b>
                <a
                  href="tel:+919080472321"
                  className="text-decorantion-none text-dark"
                >
                  <h3 className="fs-6 font_500 mb-0 pb-1">+91 9080472321</h3>
                </a>
                <a
                  href="tel:+918973047262"
                  className="text-decorantion-none text-dark"
                >
                  <h3 className="fs-6 font_500 mb-0">+91 8973047262</h3>
                </a>
              </div>
              <div>
                <b className="font_600 color_1">Training Timings : </b>
                <span className="d-block">
                <b className="font_600">Morning</b> : 6:00 AM TO 2.00 PM
                </span>
                <span className="d-block">
                  <b className="font_600">Evening</b> : 4:00 AM TO 8.00 PM
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center border-top font_400 pt-2 fs-7">
          Copyright © {new Date().getFullYear()} Sri saraswathi type institute.
          All right reserved.
        </div>
      </footer>
    </>
  );
}
