import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import "./../style.css";
import "./header.css";
export default function Header() {
  const [scroll, setScroll] = useState(false);
  const [showResults, setShowResults] = React.useState(false);
  const onClick = () => setShowResults(!showResults);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10);
    });
  }, []);
  function menuItemClick() {
    setShowResults(false);
  }
  return (
    <>
      <header className={scroll ? "main_head scrolled" : "main_head"}>
        <div className="container position-relative">
          <div className="d-block d-lg-none text-center mobile_logo">
            <Link to="/">
              <img
                src="/images/type-insitute-logo.png"
                alt="tamil-type-writing"
                title="type-writing-institute-rajapalayam"
                width="170"
                height="195"
              />
            </Link>
          </div>
          <div className="top_info">
            <div className="row">
              <div className="col-md-6 d-flex align-items-center top_info_1">
                <figure className="mb-0 pe-2">
                  <img
                    src="/images/computer-office-automation-courses.png"
                    alt="type-writing-courses"
                    title="type-writing-institute-alangulam"
                    width="40"
                    height="40"
                  />
                </figure>
                <h1 className="mb-0 fs-4 mt-0 font_600 color_1">
                  Government Approved Institute
                </h1>
              </div>
              <div className="col-md-6 text-center text-md-end pt-1 pt-md-0">
                <a
                  className="text-decoration-none border-end pe-2 d-inline-block"
                  href="tel:+919080472321"
                >
                  <h2 className="mb-0 font_600 num_lh fs-5">+91 9080472321</h2>
                </a>
                <a className="text-decoration-none d-inline-block" href="tel:+918973047262">
                  <h2 className="mb-0 font_600 ps-2 num_lh fs-5">
                    +91 8973047262
                  </h2>
                </a>
              </div>
            </div>
          </div>
          <nav className="navbar navbar-expand-lg">
            <button
              className="navbar-toggler"
              type="button"
              onClick={onClick}
              data-toggle="collapse"
              data-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className={
                showResults
                  ? "collapse navbar-collapse show nav_cus"
                  : "collapse navbar-collapse nav_cus"
              }
              id="navbarNav"
            >
              <ul className="navbar-nav justify-content-between align-items-center w-100">
                <li className="nav-item active">
                  <NavLink
                    onClick={menuItemClick}
                    to="/"
                    className={({ isActive }) =>
                      isActive ? "menu-active nav-link" : "nav-link"
                    }
                  >
                    <span>Home</span>
                  </NavLink>
                </li>

                <li className="nav-item dropdown">
                  <NavLink
                    onClick={menuItemClick}
                    to="/type-writing-saraswathi-courses"
                    className={({ isActive }) =>
                      isActive
                        ? "menu-active nav-link dropdown-toggle"
                        : "nav-link dropdown-toggle"
                    }
                  >
                    <span>Courses</span>
                  </NavLink>
                  <ul className="dropdown-menu menu_sub">
                    <li className="nav-item">
                      <NavLink
                        onClick={menuItemClick}
                        className="nav-link"
                        to="/type-writing-course"
                      >
                        Type writing
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        onClick={menuItemClick}
                        className="nav-link"
                        to="/shorthand-course"
                      >
                        Shorthand
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        onClick={menuItemClick}
                        className="nav-link"
                        to="/computer-office-automation-courses"
                      >
                        Computer office automation (COA)
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <NavLink
                    onClick={menuItemClick}
                    to="/type-writing-saraswathi-services"
                    className={({ isActive }) =>
                      isActive ? "menu-active nav-link" : "nav-link"
                    }
                  >
                    <span>Services</span>
                  </NavLink>
                </li>
                <NavLink to="/" className="nav-link img_list">
                  <img
                    src="/images/type-insitute-logo.png"
                    alt="tamil-type-writing"
                    title="type-writing-institute-rajapalayam"
                    width="170"
                    height="195"
                  />
                </NavLink>
                <li className="nav-item">
                  <NavLink
                    onClick={menuItemClick}
                    to="/type-institute-saraswathi-about"
                    className={({ isActive }) =>
                      isActive
                        ? "menu-active nav-link text-end"
                        : "nav-link text-end"
                    }
                  >
                    <span>About Institute</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    onClick={menuItemClick}
                    to="/type-institute-instructor"
                    className={({ isActive }) =>
                      isActive
                        ? "menu-active nav-link text-end"
                        : "nav-link text-end"
                    }
                  >
                    <span>Instructor</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    onClick={menuItemClick}
                    to="/type-institute-address"
                    className={({ isActive }) =>
                      isActive
                        ? "menu-active nav-link text-end"
                        : "nav-link text-end"
                    }
                  >
                    <span>Contact us</span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
}
