function LoadPage() {
  return (
    <div className="position-fixed d-flex align-items-center justify-content-center loading_main w-100 h-100">
      <div class="spinner-border text-secondary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}
export default LoadPage;
